import dayjs from "dayjs";

const HEADERS_JSON = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

async function request(url, options = {}) {
  const params = {...options};
  params.headers = {
    ...getHeaders(),
    ...params.headers,
    ...(options && options.headers ? options.headers : {}),
  };

  if (params.body) {
    params.body = JSON.stringify(params.body);
  }

  return fetch(`/api/${url}`, params).then(async response => {
    if (response.status === 200) {
      return {
        status: response.status,
        data: ['application/zip', 'application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(response.headers.get('Content-Type')) ?  await response.blob() : await response.json(),
      }
    } else if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.replace('/login');
    } else {
      const errorMessage = response.status === 204 ? null : await response.json().catch(e=>'');
      return {
        status: response.status,
        message: errorMessage?.data || getMessageError(response.status)
      };
    }
  });
}

export async function fetchWithFile(url, files) {
  const formData = new FormData();
  files.forEach(file => formData.append('file', file));
  const token = localStorage.getItem('token');
  return await fetch(`/api/${url}`, {
    method: "POST",
    body: formData,
    headers: {
      'Authorization': token
    }
  });
}

function getMessageError(code) {
  switch (code) {
    case 204:
      return 'Пользователь не найден!';
    case 403:
      return 'unverified account';
    case 429:
      return 'Превышено допустимое количество попыток. Попробуйте войти позже';
    case 504: 
      return 'Сайт не отвечает, попробуйте позже'    
    default:
      return 'Не известная ошибка';
  }
}

function getHeaders() {
  const token = localStorage.getItem('token');
  return {
    ...HEADERS_JSON,
    'Authorization': token,
    'Timestamp': dayjs().format('YYYY-MM-DDTHH:mm:ss'), 
  };
}

export default request;
